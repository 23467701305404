<template>
  <div v-if="modelValue" class="fixed inset-x-0 top-0 z-50 size-full">
    <div
      class="fixed top-0 z-20 flex h-10 w-full flex-row items-center justify-between bg-white px-4 py-2 text-black-60"
      :class="{ 'border-b border-solid border-black-10 shadow': showTopOverflowBorder }"
    >
      <h2 class="text-table-header">
        <slot v-if="$slots.header" name="header" />
        <span v-else>
          {{ title }}
        </span>
      </h2>
      <UiIcon name="big-close" @click="cancel" />
    </div>
    <div ref="content" class="size-full overflow-auto bg-bg-light pb-20 pt-10" @scroll="checkScroll">
      <slot name="content" />
    </div>
    <!-- Actions -->
    <div
      class="fixed bottom-0 z-10 flex w-full flex-row gap-4 bg-white p-4"
      :class="[
        secondaryButtonText ? 'justify-between' : 'justify-center',
        {
          'border-t border-solid border-black-10 shadow-up': showBottomOverflowBorder,
        },
      ]"
    >
      <UiButtonBase
        v-if="secondaryButtonText"
        id="popup_secondary"
        type="secondary"
        size="big"
        class="!w-1/2"
        @click="cancel"
      >
        {{ secondaryButtonText }}
      </UiButtonBase>
      <UiButtonBase
        v-if="primaryButtonText"
        id="popup_primary"
        class="!w-1/2"
        size="big"
        :disabled="disabled"
        @click="confirm"
        >{{ primaryButtonText }}</UiButtonBase
      >
    </div>
  </div>
</template>

<script setup lang="ts">
const emits = defineEmits(['confirm', 'cancel'])

const modelValue = defineModel<boolean>()

type Props = {
  title?: string
  primaryButtonText?: string
  secondaryButtonText?: string
  disabled?: boolean
}

withDefaults(defineProps<Props>(), {
  title: '',
  primaryButtonText: '',
  secondaryButtonText: '',
})

let contentOverflows = ref(false)
const showTopOverflowBorder = ref(false)
const showBottomOverflowBorder = ref(false)
const content = ref<HTMLElement>()

onMounted(() => {
  contentOverflows = computed(() => Number(content?.value?.scrollHeight) > Number(content?.value?.offsetHeight))
  setTimeout(() => {
    checkScroll()
  })
})

const checkScroll = () => {
  if (contentOverflows.value) showTopOverflowBorder.value = Number(content.value?.scrollTop) > 0
  showBottomOverflowBorder.value =
    Math.abs(
      Number(content.value?.scrollHeight) - Number(content.value?.scrollTop) - Number(content.value?.clientHeight)
    ) > 1
}

const cancel = () => {
  emits('cancel')
  modelValue.value = false
}

const confirm = () => {
  emits('confirm')
}
</script>

<style scoped></style>
