<template>
  <UiMobilePanel
    v-model="modelValue"
    primary-button-text="Apply"
    secondary-button-text="Cancel"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <template #header>
      <div class="flex flex-row items-center gap-2">
        Filters
        <div
          v-if="filterResultCount"
          class="text-subhead-3 flex size-6 items-center justify-center rounded-full bg-primary-100 text-white"
        >
          {{ filterResultCount }}
        </div>
      </div>
    </template>
    <template #content>
      <div class="flex flex-col">
        <Transition name="fade">
          <div v-if="currentFiltersWithValues.length" class="flex flex-col gap-2 px-4 pt-4">
            <div class="flex flex-row gap-x-3 gap-y-2 overflow-x-auto pt-2 xs:flex-wrap">
              <transition-group name="fade">
                <div
                  v-for="([key, filterValue], index) in currentFiltersWithValues"
                  :key="index"
                  class="flex flex-row gap-2"
                >
                  <div v-if="props.filtersMap.get(key)?.customText && filtersMap.get(key)?.customText(filterValue)">
                    <UiTagSecondary
                      :id="`tag_${filterValue}`"
                      right-icon="small-close"
                      icon-clickable
                      height-class="h-7"
                      @icon-clicked="localFilters[key] = typesInitValues.get(typeof filterValue) || []"
                    >
                      <template #content>
                        <div class="flex flex-row items-center justify-center gap-1">
                          <span class="text-caption"> {{ props.filtersMap.get(key)?.name }}: </span>
                          <span class="text-caption-2">
                            {{
                              // @ts-ignore
                              props.filtersMap.get(key)?.customText(filterValue)
                            }}
                          </span>
                        </div>
                      </template>
                    </UiTagSecondary>
                  </div>
                  <div v-else class="flex flex-row gap-x-3 gap-y-2 xs:flex-wrap">
                    <UiTagSecondary
                      v-for="(value, valueIndex) in filterValue"
                      :id="`tag_${value}`"
                      :key="valueIndex"
                      right-icon="small-close"
                      icon-clickable
                      height-class="h-7"
                      @icon-clicked="localFilters[key as keyof typeof localFilters]?.splice(valueIndex, 1)"
                    >
                      <template #content>
                        <div class="flex flex-row items-center justify-center gap-1">
                          <span class="text-caption"> {{ props.filtersMap.get(key)?.name }}: </span>
                          <span class="text-caption-2">
                            {{ getName(key, value) }}
                          </span>
                        </div>
                      </template>
                    </UiTagSecondary>
                  </div>
                </div>
              </transition-group>
            </div>
            <UiButtonBase id="clear-filters" type="secondary" class="w-full" @click="clearAll">Clear all </UiButtonBase>
          </div>
        </Transition>
        <div class="flex flex-col gap-3 p-4">
          <div v-for="[key] in currentFilters" :key="key" class="rounded-xl bg-white px-4 py-2">
            <div
              class="text-subhead-3 flex flex-row items-center justify-between text-black-70"
              @click="filtersOpened[key] = !filtersOpened[key]"
            >
              {{ filtersMap.get(key)?.name }}
              <UiIcon
                :class="[{ 'rotate-180': !filtersOpened[key] }, 'transition-all duration-200']"
                name="chevron-big-down"
              />
            </div>

            <Collapse :when="filtersOpened[key]" class="height-transition">
              <div v-if="filtersMap.get(key)?.component" class="mt-2">
                <component
                  :is="filtersMap.get(key)?.component"
                  v-model="localFilters[key as keyof typeof localFilters]"
                  :name="key"
                  :placeholder="filtersMap.get(key)?.name"
                  :is-extra="!!filtersMap.get(key)?.extra"
                  v-bind="filtersMap.get(key)?.props"
                  @remove="() => delete localFilters[key as keyof typeof localFilters]"
                />
              </div>
              <div
                v-else-if="Array.isArray(localFilters[key as keyof typeof localFilters])"
                class="mt-2 flex flex-col gap-2"
              >
                <UiInputSearch
                  v-if="filtersMap.get(key)?.props?.withSearch"
                  v-model="filtersSearch[key]"
                  @search="filtersSearch[key] = $event"
                />
                <div v-for="item in itemsToShow(key)" :key="item.value" class="mt-2 flex flex-col">
                  <div>
                    <UiInputCheckbox
                      v-model="localFilters[key as keyof typeof localFilters]"
                      :name="item.text"
                      :value="item.value"
                      :label="item.text"
                      @click.stop
                    />
                  </div>
                </div>
                <UiButtonGhost
                  v-if="props.filtersMap.get(key)?.props?.withSearch"
                  id="see all"
                  class="mt-2"
                  @click="showFilterPopup(key)"
                >
                  See all
                </UiButtonGhost>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </template>
  </UiMobilePanel>
</template>

<script setup lang="ts">
import { Collapse } from 'vue-collapsed'
import { POPUPS } from '../dynamic/maps'
import { useUiStore } from '~/store/ui'
import type { DynamicFilter, Filters } from '~/types'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'input', 'reset'])

const modelValue = defineModel<boolean>()

type Props = {
  filters: Filters
  filtersMap: Map<string, DynamicFilter>
  currentFilters: Array<[string, any[]]>
  filterResultCount: number
}

const props = defineProps<Props>()

const localFilters = ref({ ...props.filters })

// Init all expansion panels as opened
const filtersOpened = ref(Object.fromEntries(props.currentFilters.map(([key]) => [key, true])))

// Init all search inputs as empty
const filtersSearch = ref(Object.fromEntries(props.currentFilters.map(([key]) => [key, ''])))

const itemsToShow = (key: string) => {
  const itemsToShow = props.filtersMap
    .get(key)
    ?.items?.filter((i) => i.text.toLowerCase().includes(filtersSearch.value[key].toLowerCase()))
    .sort((a, b) => localFilters.value[key].includes(b.value) - localFilters.value[key].includes(a.value))

  return props.filtersMap.get(key)?.props?.withSearch ? itemsToShow?.slice(0, 5) : itemsToShow
}

const currentFiltersWithValues = computed(() =>
  Object.entries(localFilters.value).filter((i) => Array.isArray(i[1]) && i[1]?.length)
)

const typesInitValues = new Map<string, any>([
  ['number', 0],
  ['string', ''],
])

const clearAll = () => {
  localFilters.value = Object.fromEntries(
    Object.entries(props.filters).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [key, []]
      }
      return [key, typesInitValues.get(typeof value)]
    })
  )
}

const submit = () => {
  emits('input', localFilters.value)
  emits('update:modelValue', false)
}

const getName = (key: string, value: number) => {
  return props.filtersMap.get(key)?.items?.find((i) => i.value === value)?.text
}

const showFilterPopup = (key: string) => {
  uiStore.showPopup(
    POPUPS.FILTERS_MOBILE_PANEL_SEE_MORE,
    {
      filterKey: key,
      filters: localFilters.value,
      currentFilter: props.filtersMap.get(key),
    },
    {
      input: (values: any) => {
        localFilters.value[key as keyof typeof localFilters] = values
      },
    }
  )
}
</script>

<style scoped></style>
